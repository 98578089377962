import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../App.css";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import HrloadingScreen from "../../../Hr/LodingHrscreen";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../StyleAdminComponents/User.css";
import BulkUserDeleteDialog from "./Components/BulkDeleteDialog";
import BulkDialog from "./Components/BulkDialog";
import SendEmailPopUp from "./sendEmailPopUp";
import "./Users.css";
function Users() {
  const navigate = useNavigate();
  const goToAddNewUser = (state) =>
    navigate("/admin/addNewUser", { state: { ...state } });
  const goToEditUser = (state) => {
    let zz = getCompanyNameFromId(state.companyId);
    console.log(zz);
    navigate("/admin/userview", { state: { ...state, companyName: zz } });
  };
  const goToHome = () => navigate("/");
  const [valueSelect, setvalue] = useState("");

  const initState = {
    name: "",

    company: "",

    jobTitle: "",
    role: "",
    selected: "default",
  };
  const [query, setQuery] = useState(initState);
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);
  const [showLoading, setLoading] = useState(true);
  const [showBulk, setShowBulk] = useState(false);
  const [showDeleteBulk, setShowDeleteBulk] = useState(false);
  useEffect(() => {
    let configExInt = {
      method: "get",

      url: `${IP}/adminUserProfile/userListDuplicate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(configExInt).then((res) => {
      console.log(res);
    });
  }, []);

  const [userArray, setUserArray] = useState([]);
  const [details, setDetails] = useState([]);
  const [storeUserLastDataList, setStoreUserLastDataList] = useState({});
  const [curentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const getUserList = httpsCallable(functions, "getUserList");
  const archiveUser = httpsCallable(functions, "archiveUser");
  const unarchiveUser = httpsCallable(functions, "unarchiveUser");
  const inviteUserRemainder = httpsCallable(functions, "inviteUserRemainder");
  const userDelete = httpsCallable(functions, "userDelete");
  const { clrs } = useSelector((state) => state.createClr);
  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [userJobTitle, setUserJobTitle] = useState([]);
  const [userCompany, setUserCompany] = useState([]);
  const [userDepart, setUserDepart] = useState([]);
  const [stateTempForfetchUserList, setStateTempForfetchUserList] =
    useState(true);
  const [archivedUserList, setArchivedUserList] = useState([]);
  const [buttonTogalForarchived, setButtonTogalForarchived] = useState(true);
  const [extraArrForSaveTheUsers, setExtraArrForSaveTheUsers] = useState([]);
  const [tempLoading, setTemploading] = useState("test");
  const [selectedAll, setSelectedAll] = useState(false);

  const [loadingScreenPopUp, setLoadingScreenPopUp] = useState(false);
  const [openSendReport, setOpenSendReport] = useState(false);
  const [newEmployeeArray, setNewEmployeeArray] = useState([]);
  const handleQueryChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const setDefault = () => {
    setStateTempForfetchUserList(!stateTempForfetchUserList);
  };
  const handleSearch = (name) => {
    const value = query[name];
    if (value == "") {
      setDetails(userArray);
      setArrLength(userArray.length);
      return;
    }

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${IP}/adminUserProfile/userList?${name}=${value}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((res) => {
      setDetails(res.data.data);
      setArrLength(res.data.data.length);
      // //console.log(temp);
      setCurrentUrl(`${IP}/adminUserProfile/userList?${name}=${value}`);
      setStoreUserLastDataList(res.data);
      setCurrentPage(res.data.c_page);
      setPageSize(res.data.count);
      setArrLength(res.data.t_count);
      setTotalPage(res.data.t_page);
    });

    // //console.log(temp);
  };
  const handleStatusChange = (e) => {
    setQuery({
      ...query,
      role: e.target.value,
    });
    //console.log(e.target.name);
    //console.log(e.target.value);
    if (e.target.value == "default") {
      setDefault();
      return;
    }

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${IP}/adminUserProfile/userList?${"role"}=${e.target.value}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((res) => {
      setDetails(res.data.data);
      setArrLength(res.data.data.length);
      // //console.log(temp);
      setCurrentUrl(
        `${IP}/adminUserProfile/userList?${"role"}=${e.target.value}`
      );
      setStoreUserLastDataList(res.data);
      setCurrentPage(res.data.c_page);
      setPageSize(res.data.count);
      setArrLength(res.data.t_count);
      setTotalPage(res.data.t_page);
    });
  };

  const getCompanyNameFromId = (id) => {
    const findCompany = userCompany.find((item) => item.companyId === id);
    if (findCompany) return findCompany?.name || "";
    return "";
  };

  useEffect(() => {
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      if (Array.isArray(res.data)) {
        setUserCompany(res.data);
      }
    });
  }, []);

  useEffect(() => {
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${IP}/adminUserProfile/userList?limit=${10}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((res) => {
      // let res = ress.data;
      setCurrentUrl(`${IP}/adminUserProfile/userList`);
      setStoreUserLastDataList(res.data);
      if (res.data.error == "No matches found") {
        setUserArray([]);
        setDetails([]);
        setStoreUserLastDataList({});
        setArrLength(0);
        setTotalPage(1);
        setUserJobTitle([]);
        setUserCompany([]);
        setUserDepart([]);
        setLoading(false);
      } else {
        // setStoreUserLastDataList(res.data);
        setTotalPage(res.data?.t_page);
        setLoading(false);
        setUserArray(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setDetails(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setExtraArrForSaveTheUsers(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );

        setArchivedUserList(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived == true)
        );

        // if (buttonTogalForarchived) {
        //   let tempdata = res.data
        //     ?.map((item) => ({ ...item, selected: false }))
        //     .filter((item) => item.archived != true);
        //   setArrLength(tempdata.length);
        // } else {
        //   let tempdata = res.data
        //     ?.map((item) => ({ ...item, selected: false }))
        //     .filter((item) => item.archived == true);
        //   setArrLength(tempdata.length);
        // }

        setButtonTogalForarchived(true);
        setCurrentPage(res.data.c_page);
        setArrLength(res.data.t_count);
        setTotalPage(res.data.t_page);
        setPageSize(10);
        setLoading(false);
      }
    });
  }, [stateTempForfetchUserList]);

  // //console.log(userArray);
  const clickhandler = (url) => {
    // if (i < 0) return;
    // if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    setLoading(true);
    setCurrentUrl(`${url}`);

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${url}&limit=${pageSize}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setStoreUserLastDataList(res.data);
        setLoading(false);
        setUserArray(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setDetails(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setCurrentPage(res.data.c_page);
        setArrLength(res.data.t_count);
      })
      .catch((err) => {
        setLoading(false);
        toast.warning("something went wrong");
      });
  };

  const changePageLimit = (pageLimit) => {
    setLoading(true);

    let URL = curentUrl;

    if (curentUrl.split("?")[1]) {
      URL = `${URL}&limit=${pageLimit}`;
    } else {
      URL = `${URL}?limit=${pageLimit}`;
    }
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setStoreUserLastDataList(res.data);
        setLoading(false);
        setUserArray(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setDetails(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setCurrentPage(res.data.c_page);
        setArrLength(res.data.t_count);
        setTotalPage(res.data.t_page);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.warning("something went wrong");
      });
  };
  const handleChange = (event, item, id) => {
    var data = event.target.value;
    setvalue(event.target.value);
    if (data == "unarchive") {
      setTemploading(id);

      let dataex = JSON.stringify({
        userId: item.userId,
      });
      let configExInt = {
        method: "patch",

        url: `${IP}/adminUserProfile/userList`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          //  setStateTempForfetchUserList(!stateTempForfetchUserList)

          let archivedData = { ...item, archived: false };

          let dataddd = archivedUserList.filter((el, index) => index != id);
          setDetails(dataddd);
          setUserArray(dataddd);
          setExtraArrForSaveTheUsers((prev) => prev.concat(archivedData));
          setArchivedUserList(dataddd);

          setArrLength(dataddd.length);
          setTemploading("test");
        })
        .catch((err) => {
          setTemploading("test");
        });
    }
    if (data == "archive") {
      setTemploading(id);

      let dataex = JSON.stringify({
        userId: item.userId,
      });
      let configExInt = {
        method: "patch",

        url: `${IP}/adminUserProfile/userDetails`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          //  setStateTempForfetchUserList(!stateTempForfetchUserList)

          let archivedData = { ...item, archived: true };

          let dataddd = extraArrForSaveTheUsers.filter(
            (el, index) => index != id
          );
          setDetails(dataddd);
          setExtraArrForSaveTheUsers(dataddd);
          setArchivedUserList((prev) => prev.concat(archivedData));
          setUserArray(dataddd);
          //console.log(res);
          setTemploading("test");
        })
        .catch((err) => {
          setTemploading("test");
        });

      return;
    }
    if (data == "edit") {
      goToAddNewUser(item);
    } else if (data == "view") {
      goToEditUser(item);
    } else if (data == "delete") {
      setLoading(true);
      if (window.confirm("Do you want to delete this user?")) {
        let dataex = JSON.stringify({
          userId: item.userId,
        });
        let configExInt = {
          method: "delete",

          url: `${IP}/adminUserProfile`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        axios(configExInt)
          .then((res) => {
            setLoading(false);
            setStateTempForfetchUserList(!stateTempForfetchUserList);
          })
          .catch((e) => {
            setLoading(false);
            if (e?.response?.data?.message)
              toast.error(e?.response?.data?.message?.toUpperCase?.());
          });
      }
      setLoading(false);
      return;
    }
  };

  const showArchivedFunction = (type) => {
    if (type == "archived") {
      setDetails(archivedUserList);
      setUserArray(archivedUserList);
      setArrLength(archivedUserList.length);
      setCurrentPage(1);
    } else if (type == "user") {
      setDetails(extraArrForSaveTheUsers);
      setUserArray(extraArrForSaveTheUsers);
      setArrLength(extraArrForSaveTheUsers.length);
      setCurrentPage(1);
    }
    setButtonTogalForarchived(!buttonTogalForarchived);
  };

  const handelCheckBoxChech = (type, ind, item) => {
    if (buttonTogalForarchived) {
      if (type == "all") {
        if (!selectedAll) {
          let newARr = details.map((el) => ({
            ...el,
            selected: el.invitationAccept ? false : true,
          }));
          setDetails([]);
          setDetails(newARr);
        } else {
          let newARr = details.map((el) => ({ ...el, selected: false }));
          setDetails([]);
          setDetails(newARr);
        }
        setSelectedAll(!selectedAll);
      } else if (type == "one") {
        if (!details[ind].selected) {
          let newARr = [...details];
          newARr[ind].selected = true;
          ////console.log( newARr[ind].selected)
          setDetails([]);
          // //console.log(newARr)
          setDetails(newARr);
        } else {
          let newARr = [...details];
          newARr[ind].selected = false;
          ////console.log( newARr[ind].selected)
          setDetails([]);
          // //console.log(newARr)
          setDetails(newARr);
        }
      }

      if (type == "sub") {
        let count = 0;
        for (let i = 0; i < details.length; i++) {
          if (details[i].selected && !details[i].invitationAccept) {
            count++;

            let dataex = JSON.stringify({
              name: details[i].name,
              email: details[i].email,
            });
            let configExInt = {
              method: "post",

              url: `${IP}/adminUserProfile/userInvitation`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: dataex,
            };
            axios(configExInt);
          }
        }
        if (count == 0) {
          alert(`select the user invitationAccept pending`);
        } else {
          alert(`invite send to ${count} user`);
        }

        //inviteUserRemainder({name:item.name,email:item.email})
      }
    }
  };
  //console.log("ShowArchive")
  const translated = t("ShowArchive");
  //console.log(translated);

  const deletebulkuser = () => {
    let filterdata = details.filter((el) => el.selected == true);
    let dataex = JSON.stringify({ arr: filterdata });
    console.log(filterdata);
    let configExInt = {
      method: "delete",

      url: `${IP}/adminUserProfile/bulkUser`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    setLoadingScreenPopUp(true);
    axios(configExInt)
      .then((res) => {
        console.log(res.data);
        alert("Success");
        setStateTempForfetchUserList(!stateTempForfetchUserList);

        setLoadingScreenPopUp(false);
      })
      .catch((er) => {
        setLoadingScreenPopUp(false);
      });
  };
  console.log(details);
  return (
    <div className="admin-users">
      <Navbar page={t("Users")} />
      <div className="admin-users-main">
        <SendEmailPopUp
          open={openSendReport}
          onClose={() => setOpenSendReport(false)}
        />

        <HrloadingScreen
          open={loadingScreenPopUp}
          onClose={() => {
            setLoadingScreenPopUp(false);
          }}
        />
        <Sidebar page={"user"} hidepage={false} />
        <div className="admin-overdue">
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
            className="admin-dashboard-main-mid"
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("UserName")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  value={query.name}
                  name="name"
                  type="text"
                  onChange={handleQueryChange}
                  placeholder={t("Enter Username")}
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.55vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => handleSearch("name")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Search")}</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Email")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="email"
                  value={query.email}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Email"
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => handleSearch("email")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Search")}</div>
                  </button>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("JobTitle")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "11vw" }}
                  name="jobTitle"
                  value={query.jobTitle}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Job Title"
                >
                  {userJobTitle?.map((item) => (
                    <option value={item}>{item.toUpperCase()}</option>
                  ))}
                </select>
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => handleSearch("jobTitle")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Filter")}</div>
                  </button>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Company")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "11vw" }}
                  name="companyId"
                  value={query.companyId}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Company"
                >
                  {userCompany?.map((item) => (
                    <option value={item.companyId}>{item.name}</option>
                  ))}
                </select>

                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => handleSearch("companyId")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Filter")}</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Department")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "11vw" }}
                  type="text"
                  value={query.department}
                  name="department"
                  onChange={handleQueryChange}
                  placeholder="Search By Department"
                >
                  {userDepart?.map((item) => (
                    <option value={item}>{item.toUpperCase()}</option>
                  ))}
                </select>

                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => handleSearch("department")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Filter")}</div>
                  </button>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Role")}
              </div>
              <div>
                <select
                  style={{ width: "11vw" }}
                  // defaultValue={query.role}
                  onChange={handleStatusChange}
                  name="role"
                  id="SelectRole"
                >
                  <option
                    value="default"
                    selected={query.role == "default" ? true : false}
                  >
                    {t("SelectRole")}
                  </option>
                  <option value="ADMIN">{t("Admin")}</option>
                  <option value="USER">{t("User")}</option>
                  <option value="HR">{t("HR")}</option>
                </select>
              </div>
            </div>
            <div
              class="admin-dashboard-main-top-last"
              style={{ height: " 16vh", width: "10vh" }}
            >
              <button
                style={{
                  width: "10vw",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={setDefault}
              >
                <div>
                  <svg
                    width="auto"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>{t("ClearFilters")}</div>
              </button>
            </div>

            <div
              class="admin-dashboard-main-top-last"
              style={{ height: " 16vh", width: "10vh" }}
            >
              {buttonTogalForarchived ? (
                // <button
                //   style={{
                //     width: "10vw",
                //     background: `${
                //       clrs.CourseListBtn
                //         ? clrs.CourseListBtn
                //         : "hwb(224deg 78% 3%)"
                //     }`,
                //   }}
                //   onClick={() => {
                //     showArchivedFunction("archived");
                //   }}
                // >
                //   <div></div>
                //   <div style={{ marginLeft: "-2vw" }}>{t("ShowArchive")}</div>
                // </button>
                <></>
              ) : (
                <button
                  style={{
                    width: "10vw",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    showArchivedFunction("user");
                  }}
                >
                  <div></div>
                  <div style={{ marginLeft: "-2vw" }}>{t("ShowUsers")}</div>
                </button>
              )}
            </div>
          </div>
          <div style={{ height: "auto" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
            <div
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                marginTop: "8vh",
                marginLeft: "0vw",
              }}
              className="head"
            >
              {t("UserList")}
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <button
                onClick={() => {
                  setShowDeleteBulk(true);
                  // deletebulkuser();
                }}
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  width: "13vw",
                  fontWeight: "500",
                  height: "8vh",
                  marginBottom: "1em",
                }}
              >
                {t("SendGrid Status")}
              </button>
              <button
                onClick={() => {
                  setOpenSendReport(true);

                  // handelCheckBoxChech("sub", "id", "dataj");
                }}
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  width: "13vw",
                  fontWeight: "500",
                  height: "8vh",
                  marginBottom: "1em",
                }}
              >
                {t("SendReminder")}
              </button>
              <button
                onClick={() => {
                  setShowBulk(true);
                  // //console.log(Papa.parse("a,b,c\n1,2,3"));
                }}
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  width: "13vw",
                  fontWeight: "500",
                  height: "8vh",
                  marginBottom: "1em",
                }}
              >
                {t("Bulk Task")}
              </button>

              <Link to={"/admin/addNewUser"} style={{ marginBottom: "1em" }}>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "13vw",
                    fontWeight: "500",
                    height: "8vh",
                  }}
                >
                  {t("AddNewUser")}
                </button>
              </Link>
              <BulkDialog
                showBulk={showBulk}
                handleClose={() => {
                  setShowBulk(false);
                }}
              />

              <BulkUserDeleteDialog
                showBulk={showDeleteBulk}
                handleClose={() => {
                  setShowDeleteBulk(false);
                }}
              />
            </div>
          </div>

          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt-usr"
                style={{
                  gridTemplateColumns: "0.5vw 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div
                  className="admin-overdue-bottom-table-head"
                  style={{
                    marginLeft: "-8px",
                  }}
                >
                  <input
                    style={{
                      height: "1vw",
                      width: "1vw",
                      position: "relative",
                      top: "5%",
                      left: "-10%",
                    }}
                    type="checkbox"
                    checked={selectedAll}
                    //  value={selectedAll}
                    onClick={(e) => {
                      handelCheckBoxChech("all", "no", e.target.checked);
                    }}
                  />
                </div>
                <div className="admin-overdue-bottom-table-head">{t("")}</div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Company")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("Email")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("InviteAccept")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Role")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {details.data?.length == 0 && !showLoading ? (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div> No User found</div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              ) : null}
              {details ? (
                details?.map((item, id) => {
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-usr"
                      key={id}
                      style={{
                        gridTemplateColumns: "1vw 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        <input
                          style={{
                            height: "1vw",
                            width: "1vw",
                            position: "relative",
                            top: "5%",
                            left: "-10%",
                          }}
                          type="checkbox"
                          checked={item.selected}
                          onChange={(e) => {
                            handelCheckBoxChech("one", id, e.target.checked);
                          }}
                        />
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {" "}
                        {item?.employeeId}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.name}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {getCompanyNameFromId(item?.companyId)}
                      </div>

                      <div className="admin-overdue-bottom-table-td">
                        {item?.email}
                      </div>

                      <div className="admin-overdue-bottom-table-td">
                        {item?.invitationAccept ? "Accepted" : "Pending"}
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#FD5353" }}
                      >
                        {item.role}
                      </div>

                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      >
                        <div>
                          {item.archived ? (
                            tempLoading == id ? (
                              <p>Loading...</p>
                            ) : (
                              <select
                                style={{
                                  border: "none",
                                  backgroundColor: "#F2F2F2",
                                  height: "4vh",
                                  width: "7vw",
                                  padding: "0 1vh",
                                  fontWeight: "700",
                                  color: "#717579",
                                  borderRadius: "6px",
                                }}
                                onChange={(event) =>
                                  handleChange(event, item, id)
                                }
                              >
                                <option /* value={details.groupName} */>
                                  {t("Action")}
                                </option>

                                <option
                                  value={
                                    "unarchive"
                                  } /* value={details.groupName} */
                                >
                                  {t("UnArchive")}
                                </option>
                              </select>
                            )
                          ) : tempLoading == id ? (
                            <p>Loading...</p>
                          ) : (
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={(event) =>
                                handleChange(event, item, id)
                              }
                            >
                              <option /* value={details.groupName} */>
                                {t("Action")}
                              </option>
                              <option
                                /* value={details.groupName}
                              id={details.groupName} */
                                value={"edit"}
                              >
                                {t("Edit")}
                              </option>
                              <option
                                value={"view"} /* value={details.groupName} */
                              >
                                {t("View")}
                              </option>
                              {/* <option
                                value={
                                  "archive"
                                } 
                                
                              >
                                {t("Archive")}
                              </option> */}
                              <option
                                value={"delete"} /* value={details.groupName} */
                              >
                                {t("Delete")}
                              </option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No user </p>
              )}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => {
                      if (storeUserLastDataList?.prev == "") {
                        return;
                      }
                      clickhandler(storeUserLastDataList?.prev);
                    }}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage} of {totalPage}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => {
                      if (storeUserLastDataList?.next == "") {
                        return;
                      }
                      clickhandler(storeUserLastDataList?.next);
                    }}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-23vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => {
                      changePageLimit(e.target.value);
                      setPageSize(e.target.value);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
            <div>
              {/*    <Link to={"/admin/addNewUser"}>
              <button>add new user</button>
            </Link> */}
              {/*  <Link to={"/admin/userview"}>
              <button>userview</button>
            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
