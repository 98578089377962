import { Button } from "@mui/material";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { functions } from "../../../../Firebase";
import { ItemContext } from "../Context/ItemContext";
import QuizContainer from "./QuizContainer";
const QuizEndPage = ({ quizInfo, score, questionSet, quizId, setMode }) => {
  const { itemsArray, courseId, progressIndex, setProgressIndex } =
    useContext(ItemContext);
  const passScore = quizInfo?.extraInfo?.passScore ?? 0;
  const precent = Math.ceil((score / questionSet.length) * 100);
  const [pass, setPass] = useState(Boolean(precent >= passScore));
  const startTime = localStorage.getItem("startTime");
  const timeTaken = Math.floor((new Date().getTime() / 1000 - startTime) / 60);
  const getItemsArray = httpsCallable(functions, "getItemsArray");
  const setQuizProgress = httpsCallable(functions, "setQuizProgress");
  const resetItemProgress = httpsCallable(functions, "resetItemProgress");
  const [flag, setFlag] = useState(false);
  const [testststs, setDagsjdj] = useState(true);
  const [nextIndex, setNextIndex] = useState(null);
  //console.log("quizinfo:", quizInfo);
  const nextNode = () => {
    for (let i = progressIndex; i < itemsArray.length; i++) {
      if (itemsArray[i].type == "node") return itemsArray[i];
    }
  };
  const itemsArrayCallback = (result) => {
    // //console.log("topic", result.data);
    // //console.log(itemsArray)
    let index = itemsArray.findIndex((ele) => ele.id == result.data[0].id);
    // //console.log(index)
    setNextIndex(index);

    resetItemProgress({ courseId: courseId, itemArray: result.data }).then(
      (res) => console.log("")
    );
  };

  ////console.log(progressIndex,itemsArray,nextIndex)
  useEffect(() => {
    if (!pass) {
      // find the next node item and its type,
      //extract all the items under that topic or lesson or course
      //print it

      // for(let i=progressIndex;i>=0;i--){
      //   if(itemsArray[i]?.type!=="head"){
      //     continue
      //   }else{
      //     //console.log(i)
      //     break
      //   }
      // }

      let node = nextNode(itemsArrayCallback);
      ////console.log(node);
      if (node.nodeType == "topic") {
        let configrecrut = {
          method: "get",

          url: `${IP}/userItemDetails`,
          params: { topicId: node.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataregionf",
        };
        axios(configrecrut).then(itemsArrayCallback);
      } else if (node.nodeType == "lesson") {
        let configrecrut = {
          method: "get",

          url: `${IP}/userItemsDetails/`,
          params: { lessonId: node.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataregionf",
        };
        axios(configrecrut).then(itemsArrayCallback);
      } else if (node.nodeType == "course") {
        let configrecrut = {
          method: "get",

          url: `${IP}/userItemDetails`,
          params: { courseId: node.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataregionf",
        };
        axios(configrecrut).then(itemsArrayCallback);
      }
    } else {
      setNextIndex(progressIndex + 1);
      // setQuizProgress({
      //   courseId: courseId,
      //   quizId: quizId,
      //   lastVisited: new Date().toString(),
      // });

      let configrecrut = {
        method: "post",

        url: `${IP}/userProgress/quiz/`,
        //  params: { courseId: node.id},
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          quizId: quizId,
          lastVisited: new Date().toString(),
        }),
      };
      axios(configrecrut)
        .then((res) => {
          setNextIndex(progressIndex + 1);

          // setCompleted(true);
        })
        .catch((err) => {
          // toast.error("Network error! Please retry..");
          // loading screen with retry for user action
        });
    }
  }, [pass]);

  const quizCompleteFun = () => {
    let configrecrut = {
      method: "post",

      url: `${IP}/userProgress/quiz/`,
      //  params: { courseId: node.id},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        quizId: quizId,
        lastVisited: new Date().toString(),
      }),
    };
    axios(configrecrut)
      .then((res) => {
        setNextIndex(progressIndex + 1);
        setProgressIndex(progressIndex + 1);
        // setCompleted(true);
      })
      .catch((err) => {
        toast.error("Something went wrong, please retry");

        // loading screen with retry for user action
      });
  };
  useEffect(() => {}, [flag]);
  return (
    <>
      {testststs ? (
        <div className="quizItem-container" style={{ height: "53vh" }}>
          <div style={{ margin: "2em" }}>
            {/* {pass ? <EndQuizPass /> : <EndQuizFail />} */}
          </div>
          <div>
            <div
              className="quiziEnd-heading"
              style={{
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: "600",
                marginBottom: "30px",
              }}
            >
              {pass ? "Quiz completed successfully!" : "You failed the quiz!"}
            </div>
            {/* <div className="quiziEnd-score">{precent}% Score</div> */}
            <div
              className="quiziEnd-subheading-detail"
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "400",
              }}
            >
              {pass ? "" : "You need to revise the material."}
            </div>
            {/* <div className="quiziEnd-subheading">
              Quiz Completion Time
              <div className="quiziEnd-subheading-detail">
                {" "}
                {startTime ? timeTaken + " Minutes" : ""}
              </div>
            </div> */}
            {/* <div className="quiziEnd-subheading">
              You attempted{" "}
              <div className="quiziEnd-subheading-detail">
                {questionSet.length} Questions
              </div>{" "}
              And From That {score} Answer Is Correct.
            </div> */}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            {pass ? (
              <Button
                onClick={() => {
                  localStorage.removeItem("startTime");

                  localStorage.removeItem("QuestionNum");
                  localStorage.removeItem("answer");

                  quizCompleteFun();
                  // if (nextIndex !== null) {
                  //   quizCompleteFun();
                  //   setProgressIndex(nextIndex);
                  // }
                }}
                className="quizItem-button"
                sx={{
                  color: "#6846C7",
                  border: "1px solid #c3b5e9",
                  borderRadius: "16px",
                }}
              >
                Continue
              </Button>
            ) : (
              <Button
                onClick={() => {
                  // if(itemsArray?.length-1===progressIndex){
                  //   setFlag(false)
                  //   alert("i am from end quiz")
                  //   setProgressIndex(0);
                  // }else{
                  //setDagsjdj(false)

                  //  //console.log(itemsArray[progressIndex])
                  //console.log("im in side endQuiz", progressIndex);
                  // setProgressIndex(progressIndex);
                  setMode(1);
                  if (nextIndex !== null) {
                    console.log("im in side endQuiz");
                    // setProgressIndex(nextIndex);
                  }
                }}
                className="quizItem-button"
                sx={{
                  color: "#6846C7",
                  border: "1px solid #c3b5e9",
                  borderRadius: "16px",
                }}
              >
                Retry
              </Button>
            )}
          </div>
        </div>
      ) : (
        <QuizContainer quizId={quizId} />
      )}
    </>
  );
};

export default QuizEndPage;
